<template>
  <v-dialog :retain-focus="false" v-model="visibility" width="700px">
    <v-card width="700px">
      <v-card-title v-if="isEdit">{{
        "hoozie_rewards_editting_label" | localize
      }}</v-card-title>
      <v-card-title v-else>Hoozie rewards create</v-card-title>

      <!-- <div
        v-for="(bubble, index) in bubblesList.slice(0, 4)"
        class="mb-5"
        :key="bubble.id"
      >
        <ckeditor
          :editor="editor"
          v-model="bubblesListForEdit[index].title_en"
          :config="editorConfig"
          tabindex="1"
        ></ckeditor>
      </div> -->
      <v-col>
        <v-row no-gutters align="center" justify="center">
          <p @click="tab = 0" :class="tab == 0 ? 'tabActive' : 'tabInActive'">
            EN
          </p>
          <p @click="tab = 1" :class="tab == 1 ? 'tabActive' : 'tabInActive'">
            ES
          </p>
        </v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item v-show="tab == 0">
            <Loader v-if="showLoader" />
            <v-col v-else-if="isTree">
              <div
                v-for="(bubble, index) in bubblesList.slice(0, 4)"
                class="mb-5"
                :key="bubble.id"
              >
                <div
                  :style="
                    $v.bubblesListForEdit.$each[index].title_en.$invalid
                      ? 'border: 2px solid red'
                      : ''
                  "
                >
                  <ckeditor
                    :editor="editor"
                    v-model="bubblesListForEdit[index].title_en"
                    :config="editorConfig"
                    tabindex="1"
                  ></ckeditor>
                </div>
                <span
                  class="error--text"
                  style="font-size: 13px"
                  v-if="$v.bubblesListForEdit.$each[index].title_en.$invalid"
                  >{{
                    "attractions_validation_description_required" | localize
                  }}</span
                >
              </div>
            </v-col>
            <v-col v-else>
              <div
                v-for="(bubble, i) in bubblesList.slice(4, 7)"
                class="mb-5"
                :style="i == 2 ? 'margin-bottom: 60px!important' : ''"
                :key="bubble.id"
              >
                <div
                  :style="
                    $v.bubblesListForEdit.$each[i + 4].title_en.$invalid
                      ? 'border: 2px solid red'
                      : ''
                  "
                >
                  <ckeditor
                    :editor="editor"
                    v-model="bubblesListForEdit[i + 4].title_en"
                    :config="editorConfig"
                    tabindex="1"
                  ></ckeditor>
                </div>
                <span
                  class="error--text"
                  style="font-size: 13px"
                  v-if="$v.bubblesListForEdit.$each[i + 4].title_en.$invalid"
                  >{{
                    "attractions_validation_description_required" | localize
                  }}</span
                >
              </div>
            </v-col>
          </v-tab-item>
          <v-tab-item v-show="tab == 1">
            <Loader v-if="showLoader" />
            <v-col v-else-if="isTree">
              <div
                v-for="(bubble, index) in bubblesList.slice(0, 4)"
                class="mb-5"
                :key="bubble.id"
              >
                <div
                  :style="
                    $v.bubblesListForEdit.$each[index].title_es.$invalid
                      ? 'border: 2px solid red'
                      : ''
                  "
                >
                  <ckeditor
                    :editor="editor"
                    v-model="bubblesListForEdit[index].title_es"
                    :config="editorConfig"
                    tabindex="1"
                  ></ckeditor>
                </div>
                <span
                  class="error--text"
                  style="font-size: 13px"
                  v-if="$v.bubblesListForEdit.$each[index].title_es.$invalid"
                  >{{
                    "attractions_validation_description_required" | localize
                  }}</span
                >
              </div>
            </v-col>
            <v-col v-else>
              <div
                v-for="(bubble, index) in bubblesList.slice(4, 7)"
                class="mb-5"
                :key="bubble.id"
              >
                <div
                  :style="
                    $v.bubblesListForEdit.$each[index + 4].title_es.$invalid
                      ? 'border: 2px solid red'
                      : ''
                  "
                >
                  <ckeditor
                    :editor="editor"
                    v-model="bubblesListForEdit[index + 4].title_es"
                    :config="editorConfig"
                    tabindex="1"
                  ></ckeditor>
                </div>
                <span
                  style="color: red"
                  v-if="
                    $v.bubblesListForEdit.$each[index + 4].title_es.$invalid
                  "
                  >{{
                    "attractions_validation_description_required" | localize
                  }}</span
                >
              </div>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-card-actions>
        <v-row no-gutters justify="end">
          <v-btn text @click="$emit('close')">{{
            "button_cancel" | localize
          }}</v-btn>
          <v-btn color="#E62076" v-if="!isEdit" dark>{{
            "button_create" | localize
          }}</v-btn>
          <v-btn color="#E62076" v-else dark @click="saveBubbles">{{
            "button_update" | localize
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Editor from "../../../ckeditor5/build/ckeditor";
import { mapGetters } from "vuex";
import Loader from "../UI/Loader.vue";

export default {
  mixins: [validationMixin],
  components: { Loader },
  data: () => ({
    tab: 0,
    bubblesListForEdit: [],
    bubblesListLocal: [],
    editor: Editor,
    editorConfig: {},
    showLoader: true,
    upload: false,
  }),
  props: {
    visible: {
      require: false,
    },
    isEdit: {
      require: false,
    },
    bubblesList: {
      require: true,
    },
    isTree: {
      require: true,
    },
  },
  validations: {
    bubblesListForEdit: {
      $each: {
        title_en: { required },
        title_es: { required },
      },
    },
  },
  mounted() {
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
    this.bubblesListForEdit = this.bubblesList;
    setTimeout(() => {
      this.upload = true;
    }, 120);
    this.editorConfig.placeholder =
      this.userLocale == "en" ? "Description" : "Descripción";
    this.editorConfig.language = this.userLocale == "en" ? "en" : "es";
    setTimeout(() => {
      this.showLoader = false;
    }, 2000);
  },
  methods: {
    titleEnError(i) {
      const errors = [];
      if (!this.$v.bubblesListForEdit.$each.$iter[i].title_en.$dirty) {
        return errors;
      }
      !this.$v.bubblesListForEdit.$each.$iter[i].title_en.required &&
        errors.push("hoozie_rewards_text_validation");
      return errors;
    },
    titleEsError(i) {
      const errors = [];
      if (!this.$v.bubblesListForEdit.$each.$iter[i].title_es.$dirty) {
        return errors;
      }
      !this.$v.bubblesListForEdit.$each.$iter[i].title_es.required &&
        errors.push("hoozie_rewards_text_validation");
      return errors;
    },
    saveBubbles() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("saveBubbles", this.bubblesListForEdit);
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  watch: {
    bubblesList: {
      deep: true,
      handler() {
        this.bubblesListForEdit = this.bubblesList;
      },
    },
  },
};
</script>

<style>
.tabActive {
  width: 50%;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid #e62076;
  color: #e62076;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  padding-top: 12px;
  z-index: 10;
}
.tabActive:hover {
  background-color: rgba(230, 32, 118, 0.1);
}
.tabInActive {
  width: 50%;
  text-align: center;
  cursor: pointer;
  color: silver;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  padding-top: 12px;
  z-index: 10;
}
.tabInActive:hover {
  background-color: rgba(230, 32, 118, 0.1);
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsBackgroundGroupLineMobile.svg")}}),_c('div',{staticClass:"phone"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsPhoneMobile.svg")}})])],1),_c('div',{staticClass:"map"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsMapMobile.svg")}})])],1),_c('div',{staticClass:"mapMarker"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsMapMarker.svg")}})])],1),_c('div',{staticClass:"branch"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsMobileBranch.svg")}})])],1),_c('div',{staticClass:"DescField1"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField1Mobile.svg")}}),_c('v-col',{staticStyle:{"position":"absolute","margin-bottom":"0px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"199px","-webkit-line-clamp":"3","-webkit-box-orient":"vertical","overflow":"hidden"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[0].title_en
                : _vm.bubblesList[0].title_es
            )}})])],1)],1),_c('div',{staticClass:"DescField2"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField2Mobile.svg")}}),_c('v-col',{staticStyle:{"position":"absolute","margin-bottom":"0px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"197px","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[1].title_en
                : _vm.bubblesList[1].title_es
            )}})])],1)],1),_c('div',{staticClass:"DescField3"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField3Mobile.svg")}}),_c('v-col',{staticClass:"px-0",staticStyle:{"position":"absolute","margin-bottom":"0px"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"180px","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[2].title_en
                : _vm.bubblesList[2].title_es
            )}})])],1)],1),_c('div',{staticClass:"listDiscount"},[_c('img',{attrs:{"src":require("@/assets/listDiscount.svg"),"width":"78.88px","height":"29.11px"}})]),_c('div',{staticClass:"DescField4"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField4Mobile.svg")}}),_c('v-col',{staticStyle:{"position":"absolute"},attrs:{"cols":"12"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"165px","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[3].title_en
                : _vm.bubblesList[3].title_es
            )}})])],1)],1),_c('div',{staticClass:"DescField5"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField5Mobile.svg")}}),_c('v-row',{staticClass:"listImg",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"px-2 py-0",staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('div',{staticStyle:{"height":"70px","margin-bottom":"5px","width":"80px"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsImg1.svg"),"width":"65px","height":"67px"}})]),_c('p',{staticStyle:{"font-size":"10px","position":"relative","width":"80px","height":"90px","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"innerHTML":_vm._s(
                _vm.userLocale == 'en'
                  ? _vm.bubblesList[4].title_en
                  : _vm.bubblesList[4].title_es
              )}})]),_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('div',{staticStyle:{"height":"70px","margin-bottom":"5px"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsImg2.svg"),"width":"62px","height":"70px"}})]),_c('p',{staticStyle:{"font-size":"10px","position":"relative","height":"90px","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"innerHTML":_vm._s(
                _vm.userLocale == 'en'
                  ? _vm.bubblesList[5].title_en
                  : _vm.bubblesList[5].title_es
              )}})]),_c('v-col',{staticClass:"py-0",staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('div',{staticStyle:{"height":"70px","margin-bottom":"5px","width":"80px"}},[_c('img',{staticStyle:{"margin-top":"8px"},attrs:{"src":require("@/assets/hoozieRewardsImg3.svg"),"width":"70px","height":"57px"}})]),_c('p',{staticStyle:{"font-size":"10px","position":"relative","width":"80px","height":"90px","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"innerHTML":_vm._s(
                _vm.userLocale == 'en'
                  ? _vm.bubblesList[6].title_en
                  : _vm.bubblesList[6].title_es
              )}})])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('span',{staticClass:"headerText"},[_vm._v("The Hoozie App is Your Key to the City!")]),_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":require("@/assets/hoozieRewardsHeader.svg")}}),_c('img',{attrs:{"src":require("@/assets/hoozieRewardsBackgroundGroupLine.svg")}}),_c('div',{staticClass:"phone"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsPhone.svg")}})])],1),_c('div',{staticClass:"map"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsMap.svg")}})])],1),_c('div',{staticClass:"mapMarker"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsMapMarker.svg")}})])],1),_c('div',{staticClass:"branch"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsbranch.svg")}})])],1),_c('div',{staticClass:"DescField1"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField1.svg")}}),_c('v-col',{staticClass:"px-5",staticStyle:{"margin-left":"30px","position":"absolute","margin-bottom":"25px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"410px","-webkit-line-clamp":"4","-webkit-box-orient":"vertical","overflow":"hidden"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[0].title_en
                : _vm.bubblesList[0].title_es
            )}})])],1)],1),_c('div',{staticClass:"DescField2"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField2.svg")}}),_c('v-col',{staticStyle:{"margin-left":"15px","position":"absolute","margin-bottom":"25px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"432px","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[1].title_en
                : _vm.bubblesList[1].title_es
            )}})])],1)],1),_c('div',{staticClass:"DescField3"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField3.svg")}}),_c('v-col',{staticStyle:{"margin-left":"10px","position":"absolute","margin-bottom":"25px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"300px","-webkit-line-clamp":"3","-webkit-box-orient":"vertical","overflow":"hidden"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[2].title_en
                : _vm.bubblesList[2].title_es
            )}})])],1)],1),_c('div',{staticClass:"listDiscount"},[_c('img',{attrs:{"src":require("@/assets/listDiscount.svg")}})]),_c('div',{staticClass:"DescField4"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField4.svg")}}),_c('v-col',{staticStyle:{"margin-left":"15px","position":"absolute","margin-bottom":"15px"},attrs:{"cols":"12"}},[_c('span',{staticClass:"textStyle",staticStyle:{"max-width":"346px","-webkit-line-clamp":"3","-webkit-box-orient":"vertical","overflow":"hidden"},domProps:{"innerHTML":_vm._s(
              _vm.userLocale == 'en'
                ? _vm.bubblesList[3].title_en
                : _vm.bubblesList[3].title_es
            )}})])],1)],1),_c('div',{staticClass:"DescField5"},[_c('v-row',{attrs:{"justify":"end"}},[(_vm.admin)?_c('v-btn',{style:(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
              ? 'margin-top: 23px'
              : 'margin-top: 65px; margin-right: 40px;'),attrs:{"fab":"","color":"#E62076","right":"","dark":"","small":"","top":"","absolute":""},on:{"click":function($event){return _vm.$emit('openModal')}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsField5.svg")}}),(_vm.showLoader)?_c('loader',{staticStyle:{"position":"absolute"}}):_c('v-row',{staticClass:"listImg px-1",attrs:{"justify":"center","align":"end"}},[_c('v-col',{staticClass:"pl-13",staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsImg1.svg")}}),_c('br'),_c('p',{staticStyle:{"font-size":"14px","position":"relative","max-width":"283px","max-height":"60px","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"innerHTML":_vm._s(
                _vm.userLocale == 'en'
                  ? _vm.bubblesList[4].title_en
                  : _vm.bubblesList[4].title_es
              )}})]),_c('v-col',{staticClass:"px-0",staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('img',{attrs:{"src":require("@/assets/hoozieRewardsImg2.svg")}}),_c('br'),_c('p',{staticStyle:{"font-size":"14px","position":"relative","max-width":"283px","max-height":"60px","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"innerHTML":_vm._s(
                _vm.userLocale == 'en'
                  ? _vm.bubblesList[5].title_en
                  : _vm.bubblesList[5].title_es
              )}})]),_c('v-col',{staticClass:"pr-15",staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('img',{staticClass:"ml-5 mb-5",attrs:{"src":require("@/assets/hoozieRewardsImg3.svg")}}),_c('br'),_c('div',{staticClass:"py-0 px-4"},[_c('p',{staticStyle:{"font-size":"14px","position":"relative","max-width":"183px","max-height":"60px","overflow":"hidden","text-overflow":"ellipsis"},domProps:{"innerHTML":_vm._s(
                  _vm.userLocale == 'en'
                    ? _vm.bubblesList[6].title_en
                    : _vm.bubblesList[6].title_es
                )}})])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mx-4" style="position: relative">
    <component-title title="header_hoozie_rewards_part1" />
    <v-btn
      fab
      color="#E62076"
      right
      v-if="admin"
      dark
      small
      top
      absolute
      :style="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? 'margin-top: 23px'
          : 'margin-top: 35px'
      "
      @click="openTreeModal"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-col>
      <hoozie-reward-preview-mobile
        v-if="$vuetify.breakpoint.xs"
        :bubblesList="bubblesList"
      />
      <hoozie-rewards-preview
        v-else
        :bubblesList="bubblesList"
        :showLoader="showLoader"
        :admin="admin"
        @openModal="openModal"
      />
      <hoozie-rewards-site-description
        :style="
          $vuetify.breakpoint.xs
            ? 'margin-top: 500px'
            : $vuetify.breakpoint.xl
            ? 'margin-top: 55%;'
            : 'margin-top:85%;'
        "
      />
      <!-- <v-row justify="center" class="mt-15">
        <v-col>
          <v-row justify="center">
            <span
              style="font-weight: 600"
              :style="
                $vuetify.breakpoint.xs ? 'font-size: 16px' : 'font-size: 24px'
              "
              >{{ "hoozie_rewards_label_download_app" | localize }}</span
            >
          </v-row>
          <v-row
            no-gutters
            class="mt-8"
            style="cursor: pointer"
            justify="center"
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.hoozaroundgdlcampus.domila"
              target="_blank"
            >
              <img
                src="@/assets/downloadPlayMarket.svg"
                :class="$vuetify.breakpoint.xs ? '' : 'mr-5'"
                :width="$vuetify.breakpoint.xs ? '189px' : ''"
                :height="$vuetify.breakpoint.xs ? '64px' : ''"
              />
            </a>

            <a
              href="https://apps.apple.com/us/app/hoozie/id1488219149"
              target="_blank"
            >
              <img
                src="@/assets/downloadAppleStore.svg"
                :width="$vuetify.breakpoint.xs ? '189px' : ''"
                :height="$vuetify.breakpoint.xs ? '64px' : ''"
              />
            </a>
          </v-row>
        </v-col>
      </v-row> -->
    </v-col>
    <hoozie-rewards-edit-modal
      v-if="showModal"
      :visible="showModal"
      :isEdit="isEdit"
      :bubblesList="bubblesList"
      @close="closeModal"
      @saveBubbles="updateBubbles"
      :isTree="isTree"
    />
  </div>
</template>

<script>
import hoozieRewardsPreview from "./hoozieRewardsPreview.vue";
import hoozieRewardsSiteDescription from "@/components/UI/Banners/hoozieRewardsSiteDescription.vue";
import hoozieRewardPreviewMobile from "./hoozieRewardPreviewMobile.vue";
import ComponentTitle from "../UI/ComponentTitle.vue";
import hoozieRewardsEditModal from "./hoozieRewardsEditModal.vue";
import BubblesService from "../../requests/Bubbles/BubblesService";
import { mapGetters } from "vuex";
export default {
  components: {
    hoozieRewardsPreview,
    hoozieRewardsSiteDescription,
    hoozieRewardPreviewMobile,
    ComponentTitle,
    hoozieRewardsEditModal,
  },
  data: () => ({
    showModal: false,
    showLoader: true,
    isEdit: false,
    isTree: true,
    bubblesList: [],
    admin: false,
  }),
  mounted() {
    window.scrollTo(0, 0);
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
    }
    this.getBubblesList();
  },
  methods: {
    async getBubblesList() {
      let response = await BubblesService.getBubblesList();
      this.bubblesList = response.result;
      this.showLoader = false;
    },
    openTreeModal() {
      this.isTree = true;
      this.isEdit = true;
      this.showModal = true;
    },
    openModal() {
      this.isTree = false;
      this.isEdit = true;
      this.showModal = true;
    },
    closeModal() {
      this.showLoader = true;
      this.getBubblesList();
      this.isEdit = false;
      this.showModal = false;
    },
    updateBubbles(bubblesList) {
      let count = 0
      bubblesList.forEach(async (bubbles) => {
        await BubblesService.updateItem(bubbles.id, bubbles);
        count += 1

        if (count == bubblesList.length) {
          this.getBubblesList()
          this.closeModal()
        }
      });
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
        }
      },
    },
  },
};
</script>

<style>
</style>
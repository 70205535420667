<template>
  <div>
    <v-row justify="center" no-gutters>
      <img src="@/assets/hoozieRewardsBackgroundGroupLineMobile.svg" />
      <div class="phone">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsPhoneMobile.svg" />
        </v-row>
      </div>
      <div class="map">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsMapMobile.svg" />
        </v-row>
      </div>
      <div class="mapMarker">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsMapMarker.svg" />
        </v-row>
      </div>
      <div class="branch">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsMobileBranch.svg" />
        </v-row>
      </div>
      <div class="DescField1">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsField1Mobile.svg" />
          <v-col cols="12" style="position: absolute; margin-bottom: 0px">
            <span
              class="textStyle"
              style="
                max-width: 199px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[0].title_en
                  : bubblesList[0].title_es
              "
            >
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="DescField2">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsField2Mobile.svg" />
          <v-col cols="12" style="position: absolute; margin-bottom: 0px">
            <span
              class="textStyle"
              style="
                max-width: 197px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[1].title_en
                  : bubblesList[1].title_es
              "
            ></span>
          </v-col>
        </v-row>
      </div>
      <div class="DescField3">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsField3Mobile.svg" />
          <v-col class="px-0" style="position: absolute; margin-bottom: 0px">
            <span
              class="textStyle"
              style="
                max-width: 180px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[2].title_en
                  : bubblesList[2].title_es
              "
            ></span>
          </v-col>
        </v-row>
      </div>
      <div class="listDiscount">
        <img src="@/assets/listDiscount.svg" width="78.88px" height="29.11px" />
      </div>
      <div class="DescField4">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsField4Mobile.svg" />
          <v-col cols="12" style="position: absolute">
            <span
              class="textStyle"
              style="
                max-width: 165px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[3].title_en
                  : bubblesList[3].title_es
              "
            ></span>
          </v-col>
        </v-row>
      </div>
      <div class="DescField5">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsField5Mobile.svg" />
          <v-row class="listImg" justify="center" align="center">
            <v-col cols="4" class="px-2 py-0" style="text-align: center">
              <div style="height: 70px; margin-bottom: 5px; width: 80px">
                <img
                  src="@/assets/hoozieRewardsImg1.svg"
                  width="65px"
                  height="67px"
                />
              </div>
              <p
                style="
                  font-size: 10px;
                  position: relative;
                  width: 80px;
                  height: 90px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                v-html="
                  userLocale == 'en'
                    ? bubblesList[4].title_en
                    : bubblesList[4].title_es
                "
              ></p>
            </v-col>
            <v-col cols="4" class="px-0 py-0" style="text-align: center">
              <div style="height: 70px; margin-bottom: 5px">
                <img
                  src="@/assets/hoozieRewardsImg2.svg"
                  width="62px"
                  height="70px"
                />
              </div>
              <p
                style="
                  font-size: 10px;
                  position: relative;
                  height: 90px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                v-html="
                  userLocale == 'en'
                    ? bubblesList[5].title_en
                    : bubblesList[5].title_es
                "
              ></p>
            </v-col>
            <v-col cols="4" class="py-0" style="text-align: center">
              <div style="height: 70px; margin-bottom: 5px; width: 80px">
                <img
                  src="@/assets/hoozieRewardsImg3.svg"
                  width="70px"
                  height="57px"
                  style="margin-top: 8px"
                />
              </div>
              <p
                style="
                  font-size: 10px;
                  position: relative;
                  width: 80px;
                  height: 90px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                v-html="
                  userLocale == 'en'
                    ? bubblesList[6].title_en
                    : bubblesList[6].title_es
                "
              ></p>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    userLocale: "",
  }),
  props: {
    bubblesList: {
      require: true,
    },
  },
  mounted() {
    this.setUserLocale();
  },
  methods: {
    setUserLocale() {
      this.loggedUser
        ? (this.userLocale = this.loggedUser.locale)
        : (this.userLocale = this.user.locale);
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.setUserLocale();
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.setUserLocale();
      },
    },
  },
};
</script>

<style scoped>
.phone {
  position: absolute;
  z-index: 90;
  margin-top: 20px;
}
.map {
  margin-top: 70px;
  position: absolute;
  z-index: 50;
}
.mapMarker {
  position: absolute;
  z-index: 80;
  margin-top: 172px;
  margin-left: 38px;
}
.branch {
  position: absolute;
  z-index: 50;
  margin-top: 360px;
}
.DescField1 {
  position: absolute;
  margin-top: 400px;
  margin-right: 125px;
  z-index: 60;
}
.DescField2 {
  position: absolute;
  z-index: 60;
  margin-top: 468px;
  margin-left: 106px;
}
.DescField3 {
  position: absolute;
  z-index: 60;
  margin-top: 530px;
  margin-right: 218px;
}
.DescField4 {
  position: absolute;
  z-index: 60;
  margin-top: 575px;
  margin-left: 132px;
}
.DescField5 {
  position: absolute;
  z-index: 60;
  margin-top: 700px;
}
.textStyle {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #444444;
}
.listDiscount {
  position: absolute;
  margin-top: 582px;
  margin-right: 245px;
}

.listImg {
  position: absolute;
  padding: 30px;
  font-size: 10px;
}
</style>
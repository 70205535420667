<template>
  <div>
    <v-row justify="center" no-gutters>
      <span class="headerText">The Hoozie App is Your Key to the City!</span>
      <img src="@/assets/hoozieRewardsHeader.svg" style="position: absolute" />
      <img src="@/assets/hoozieRewardsBackgroundGroupLine.svg" />
      <div class="phone">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsPhone.svg" />
        </v-row>
      </div>
      <div class="map">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsMap.svg" />
        </v-row>
      </div>
      <div class="mapMarker">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsMapMarker.svg" />
        </v-row>
      </div>
      <div class="branch">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsbranch.svg" />
        </v-row>
      </div>
      <div class="DescField1">
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsField1.svg" />
          <v-col
            cols="12"
            class="px-5"
            style="margin-left: 30px; position: absolute; margin-bottom: 25px"
          >
            <span
              class="textStyle"
              style="
                max-width: 410px;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[0].title_en
                  : bubblesList[0].title_es
              "
            >
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="DescField2">
        <v-row align="center" justify="end">
          <img src="@/assets/hoozieRewardsField2.svg" />
          <v-col
            cols="12"
            style="margin-left: 15px; position: absolute; margin-bottom: 25px"
          >
            <span
              class="textStyle"
              style="
                max-width: 432px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[1].title_en
                  : bubblesList[1].title_es
              "
            ></span>
          </v-col>
        </v-row>
      </div>
      <div class="DescField3">
        <v-row align="center" justify="end">
          <img src="@/assets/hoozieRewardsField3.svg" />
          <v-col
            cols="12"
            style="margin-left: 10px; position: absolute; margin-bottom: 25px"
          >
            <span
              class="textStyle"
              style="
                max-width: 300px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden; 
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[2].title_en
                  : bubblesList[2].title_es
              "
            ></span>
          </v-col>
        </v-row>
      </div>
      <div class="listDiscount">
        <img src="@/assets/listDiscount.svg" />
      </div>
      <div class="DescField4">
        <v-row align="center" justify="end">
          <img src="@/assets/hoozieRewardsField4.svg" />
          <v-col
            cols="12"
            style="margin-left: 15px; position: absolute; margin-bottom: 15px"
          >
            <span
              class="textStyle"
              style="
                max-width: 346px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              "
              v-html="
                userLocale == 'en'
                  ? bubblesList[3].title_en
                  : bubblesList[3].title_es
              "
            ></span>
          </v-col>
        </v-row>
      </div>
      <div class="DescField5">
        <v-row justify="end">
          <v-btn
          v-if="admin"
            fab
            color="#E62076"
            right
            dark
            small
            top
            absolute
            :style="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'margin-top: 23px'
                : 'margin-top: 65px; margin-right: 40px;'
            "
            @click="$emit('openModal')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-row>
        <v-row align="center" justify="center">
          <img src="@/assets/hoozieRewardsField5.svg" />
          <loader style="position: absolute" v-if="showLoader" />
          <v-row class="listImg px-1" justify="center" align="end" v-else>
            <v-col cols="4" style="text-align: center" class="pl-13">
              <img src="@/assets/hoozieRewardsImg1.svg" /><br />
              <p
                style="
                  font-size: 14px;
                  position: relative;
                  max-width: 283px;
                  max-height: 60px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                v-html="
                  userLocale == 'en'
                    ? bubblesList[4].title_en
                    : bubblesList[4].title_es
                "
              ></p>
            </v-col>
            <v-col cols="4" class="px-0" style="text-align: center">
              <img src="@/assets/hoozieRewardsImg2.svg" /><br />
              <p
                style="
                  font-size: 14px;
                  position: relative;
                  max-width: 283px;
                  max-height: 60px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                v-html="
                  userLocale == 'en'
                    ? bubblesList[5].title_en
                    : bubblesList[5].title_es
                "
              ></p
            ></v-col>
            <v-col cols="4" class="pr-15" style="text-align: center"
              ><img
                class="ml-5 mb-5"
                src="@/assets/hoozieRewardsImg3.svg"
              /><br />
              <div class="py-0 px-4">
                <p
                  style="
                    font-size: 14px;
                    position: relative;
                    max-width: 183px;
                    max-height: 60px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  v-html="
                    userLocale == 'en'
                      ? bubblesList[6].title_en
                      : bubblesList[6].title_es
                  "
                ></p>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "../UI/Loader.vue";
export default {
  components: { Loader },
  data: () => ({
    userLocale: "",
  }),
  props: {
    bubblesList: {
      require: true,
    },
    showLoader: {
      require: true,
    },
    admin: {
      require: true,
    }
  },
  mounted() {
    this.setUserLocale();
  },
  methods: {
    setUserLocale() {
      this.loggedUser
        ? (this.userLocale = this.loggedUser.locale)
        : (this.userLocale = this.user.locale);
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.setUserLocale();
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.setUserLocale();
      },
    },
  },
};
</script>

<style scoped>
.headerText {
  font-weight: 700;
  font-size: 53px;
  line-height: 65px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  position: absolute;
  z-index: 30;
  margin-top: 20px;
  margin-bottom: 30px;
}
.phone {
  position: absolute;
  z-index: 100;
  margin-top: 111px;
}
.map {
  position: absolute;
  z-index: 50;
  margin-top: 111px;
}
.mapMarker {
  position: absolute;
  z-index: 80;
  margin-left: 38px;
  margin-top: 212px;
}
.branch {
  position: absolute;
  z-index: 50;
  margin-top: 770px;
}
.DescField1 {
  position: absolute;
  margin-top: 825px;
  margin-right: 623px;
  z-index: 60;
  width: 495px;
}
.DescField2 {
  position: absolute;
  z-index: 60;
  margin-top: 970px;
  margin-left: 615px;
}
.DescField3 {
  position: absolute;
  z-index: 60;
  margin-top: 1092px;
  margin-right: 432px;
}
.DescField4 {
  position: absolute;
  z-index: 60;
  margin-top: 1200px;
  margin-left: 575px;
}
.DescField5 {
  position: absolute;
  z-index: 60;
  margin-top: 1445px;
}
.textStyle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  align-items: center;
  color: #444444;
  display: -webkit-box;
  letter-spacing: -1px;
}
.listDiscount {
  position: absolute;
  margin-top: 1223px;
  margin-right: 500px;
}

.listImg {
  position: absolute;
  margin-top: 15px;
  margin-left: 15px;
}
</style>
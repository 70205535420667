import store from '@/store';
import requestService from '../requestService';

export default {
    async getBubblesList() {
        const response = await requestService.get(`bubbles`)
        return response?.data
    },
    async addNewItem(form) {
        const response = await requestService.post(`bubbles`, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async deleteItem(id) {
        const response = await requestService.delete(`bubbles/${id}`, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async getItem(id) {
        const response = await requestService.get(`bubbles/${id}`)
        return response?.data
    },
    async updateItem(id, form) {
        const response = await requestService.put(`bubbles/${id}`, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
}
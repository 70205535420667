var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false,"width":"700px"},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('v-card',{attrs:{"width":"700px"}},[(_vm.isEdit)?_c('v-card-title',[_vm._v(_vm._s(_vm._f("localize")("hoozie_rewards_editting_label")))]):_c('v-card-title',[_vm._v("Hoozie rewards create")]),_c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('p',{class:_vm.tab == 0 ? 'tabActive' : 'tabInActive',on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" EN ")]),_c('p',{class:_vm.tab == 1 ? 'tabActive' : 'tabInActive',on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" ES ")])]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab == 0),expression:"tab == 0"}]},[(_vm.showLoader)?_c('Loader'):(_vm.isTree)?_c('v-col',_vm._l((_vm.bubblesList.slice(0, 4)),function(bubble,index){return _c('div',{key:bubble.id,staticClass:"mb-5"},[_c('div',{style:(_vm.$v.bubblesListForEdit.$each[index].title_en.$invalid
                    ? 'border: 2px solid red'
                    : '')},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig,"tabindex":"1"},model:{value:(_vm.bubblesListForEdit[index].title_en),callback:function ($$v) {_vm.$set(_vm.bubblesListForEdit[index], "title_en", $$v)},expression:"bubblesListForEdit[index].title_en"}})],1),(_vm.$v.bubblesListForEdit.$each[index].title_en.$invalid)?_c('span',{staticClass:"error--text",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm._f("localize")("attractions_validation_description_required")))]):_vm._e()])}),0):_c('v-col',_vm._l((_vm.bubblesList.slice(4, 7)),function(bubble,i){return _c('div',{key:bubble.id,staticClass:"mb-5",style:(i == 2 ? 'margin-bottom: 60px!important' : '')},[_c('div',{style:(_vm.$v.bubblesListForEdit.$each[i + 4].title_en.$invalid
                    ? 'border: 2px solid red'
                    : '')},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig,"tabindex":"1"},model:{value:(_vm.bubblesListForEdit[i + 4].title_en),callback:function ($$v) {_vm.$set(_vm.bubblesListForEdit[i + 4], "title_en", $$v)},expression:"bubblesListForEdit[i + 4].title_en"}})],1),(_vm.$v.bubblesListForEdit.$each[i + 4].title_en.$invalid)?_c('span',{staticClass:"error--text",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm._f("localize")("attractions_validation_description_required")))]):_vm._e()])}),0)],1),_c('v-tab-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab == 1),expression:"tab == 1"}]},[(_vm.showLoader)?_c('Loader'):(_vm.isTree)?_c('v-col',_vm._l((_vm.bubblesList.slice(0, 4)),function(bubble,index){return _c('div',{key:bubble.id,staticClass:"mb-5"},[_c('div',{style:(_vm.$v.bubblesListForEdit.$each[index].title_es.$invalid
                    ? 'border: 2px solid red'
                    : '')},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig,"tabindex":"1"},model:{value:(_vm.bubblesListForEdit[index].title_es),callback:function ($$v) {_vm.$set(_vm.bubblesListForEdit[index], "title_es", $$v)},expression:"bubblesListForEdit[index].title_es"}})],1),(_vm.$v.bubblesListForEdit.$each[index].title_es.$invalid)?_c('span',{staticClass:"error--text",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm._f("localize")("attractions_validation_description_required")))]):_vm._e()])}),0):_c('v-col',_vm._l((_vm.bubblesList.slice(4, 7)),function(bubble,index){return _c('div',{key:bubble.id,staticClass:"mb-5"},[_c('div',{style:(_vm.$v.bubblesListForEdit.$each[index + 4].title_es.$invalid
                    ? 'border: 2px solid red'
                    : '')},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig,"tabindex":"1"},model:{value:(_vm.bubblesListForEdit[index + 4].title_es),callback:function ($$v) {_vm.$set(_vm.bubblesListForEdit[index + 4], "title_es", $$v)},expression:"bubblesListForEdit[index + 4].title_es"}})],1),(
                  _vm.$v.bubblesListForEdit.$each[index + 4].title_es.$invalid
                )?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm._f("localize")("attractions_validation_description_required")))]):_vm._e()])}),0)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm._f("localize")("button_cancel")))]),(!_vm.isEdit)?_c('v-btn',{attrs:{"color":"#E62076","dark":""}},[_vm._v(_vm._s(_vm._f("localize")("button_create")))]):_c('v-btn',{attrs:{"color":"#E62076","dark":""},on:{"click":_vm.saveBubbles}},[_vm._v(_vm._s(_vm._f("localize")("button_update")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }